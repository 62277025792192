<template>
	<v-container fluid>
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('nav.exams') }}</span>
		</div>

		<v-divider class="my-2" />

		<v-row>
			<v-col cols="12" lg="8" sm="8" xs="12">
				<p class="text-body-1 text-justify" style="max-width: 90%">
					{{ $t('exams.examsHelp1') }}
				</p>
				<p class="text-body-1 text-justify" style="max-width: 90%">
					{{ $t('exams.examsHelp2') }}
				</p>
			</v-col>
			<v-col cols="12" lg="4" sm="4" xs="12">
				<p>
					<v-icon color="success"> mdi-check-all </v-icon>
					{{ $t('exams.legendWithSolution') }}
				</p>
				<p>
					<v-icon color="blue"> mdi-check </v-icon>
					{{ $t('exams.legendWithoutSolution') }}
				</p>
				<p>
					<v-icon color="error"> mdi-close </v-icon>
					{{ $t('exams.legendNothing') }}
				</p>
				<p>
					<v-icon color="yellow lighten-3"> mdi-crosshairs-off </v-icon>
					{{ $t('exams.legendNo') }}
				</p>
			</v-col>
		</v-row>

		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-row key="loadingResults" no-gutters>
					<v-col cols="12" xl="4" lg="6" xs="12" v-for="i in 6" :key="`loadingResult${i}`" class="px-1">
						<v-skeleton-loader type="card" class="my-2 mx-auto flex-grow-1 rounded-xl" max-width="1200" />
					</v-col>
				</v-row>
			</template>

			<template v-else-if="exams.length">
				<template v-for="(degree, index) in exams">
					<v-card class="my-4" :key="index" flat color="transparent" rounded="xl">
						<v-card-title>
							<strong>{{ degree.degree }}</strong>
						</v-card-title>
						<v-card-text>
							<template v-if="degree.success">
								<v-row>
									<v-col cols="12" xl="4" lg="6" xs="12" v-for="course in degree.courses" :key="course.id">
										<CourseExamsCard :course="course" />
									</v-col>
								</v-row>
							</template>
							<template v-else>
								{{ degree.response }}
							</template>
						</v-card-text>
					</v-card>
				</template>
			</template>

			<template v-else>
				<h3 class="m-4" />
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('nav.exams'),
		}
	},
	components: {
		CourseExamsCard: () => import('@/components/courses/CourseExamsCard.vue'),
	},
	data() {
		return {
			loading: true,
		}
	},
	computed: {
		...mapGetters({
			exams: 'exams/exams',
		}),
	},
	created() {
		this.fetchExams().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('exams', ['fetchExams']),
	},
}
</script>

<style scoped></style>
